import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Customers from "../components/customers"

const CustomersPage = () => (
  <Layout>
    <SEO title="Our customers" />
    <article>
      <section id="services" className="services">
        <div className="container py-5">
          <h1 className="row justify-content-center dubbelbit">Customers</h1>
          <Customers />
        </div>
      </section>
    </article>
  </Layout>
)

export default CustomersPage
